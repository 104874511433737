.reject-reasons-dropdown {
    padding-bottom: 45px;

    .dropdown-item {
        width: 100%;
    }

    .dropdown-menu {
        width: 100%;
    }

    .btn-secondary {
        color: #474547;
        border-color: #ced4da;
        width: 100%;
        text-align: left;
    }

    .btn-secondary:hover {
        border-color: #ced4da;
        color: #474547;
    }

    .dropdown-toggle::after {
        float: right;
    }

    .dropdown-toggle {
        float: right;
    }
}
