.test-diaries-page {
    .td-page {
        @at-root #{&}__description {
            padding-top: 20px;
        }
    }

    .td-type {
        padding: 5px 6px;
        border-radius: 4px;
        color: $white;
        width: fit-content;
    }
    .no-data-type {
        background-color: $td-type-blue;
    }

    .with-data-type {
        background-color: $td-type-purple;
    }
    .linked-type {
        background-color: $td-type-teal;
    }

    .install-link {
        margin-top: 5px;

        a {
            text-decoration: none !important;
        }
    }

    .td-add-btn {
        margin: 10px;
        float: right;
    }

    .td-diary-status {
        padding: 5px 6px;
        border-radius: 4px;
        color: $white;
        width: fit-content;
    }

    .td-diary-status--active {
        background-color: $rd-green;
    }

    .td-diary-status--pending {
        background-color: $rd-orange;
    }

    .td-diary-status--disabled {
        background-color: $rd-red;
    }

    .test-diaries-table {
        p {
            margin: 2px 0px;
        }

        .test-diary-icon {
            svg {
                width: $svg-size-large;
                height: $svg-size-large;
            }
        }

        svg {
            width: 17px;
            height: 17px;
            margin-left: 4px;
        }

        .diary-install-button {
            padding: 0px;
            text-decoration: none;
        }
    }

    .icon-link {
        margin-right: 1rem !important;
        padding: 7px 7px 10px 6px;
        cursor: pointer;
    }

    .icon-link:hover {
        background-color: $light-grey-3;
        border-radius: 90px;
    }

    .header-section {
        background-color: $white;
        border-bottom: 1px solid $light-grey-3;
        padding-bottom: 1rem;
    }

    .message-container {
        width: 25%;
        border: 1px solid lightgrey;
        margin: 8% auto;
        padding: 3rem;
        border-radius: 0.4rem;
        -webkit-box-shadow: -2px -2px 15px -5px rgba(176, 176, 176, 0.66);
        -moz-box-shadow: -2px -2px 15px -5px rgba(176, 176, 176, 0.66);
        box-shadow: -2px -2px 15px -5px rgba(176, 176, 176, 0.66);
    }
}

.test-diary-page {
    background-color: $white;
    width: 100%;
    .content {
        margin: 0 auto;
        width: 80rem;
        .header {
            margin-top: 1rem;
            margin-bottom: 1.5rem;
            padding-left: 1rem;
            padding-right: 1rem;
            display: flex;
            width: 100%;
            .add-diary {
                display: flex;
                margin-left: auto;
                align-items: end;
                button {
                    height: 2.25rem;
                }
            }
            .titles {
                display: block;
            }
        }
        .table {
            display: block;
        }
    }
}
