.activate-account-page {
    margin: 0 auto;

    .logo {
        display: flex;
        justify-content: center;
        margin-bottom: 2.5rem;

        svg {
            width: 12.5rem;
        }
    }

    .loading-activate-content {
        text-align: center;

        img {
            height: 4rem;
        }
    }

    .content-card {
        width: 37rem;
        background-color: $white;
        padding: 2.5rem 4.5rem;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
        border-radius: 0.25rem;

        .activation-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .title-and-password {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding-left: 3rem;
                padding-right: 3rem;
            }

            .notification-icon {
                svg {
                    width: 2.5rem;
                    height: 2.5rem;
                    margin-bottom: 2.5rem;
                }
            }

            .title {
                margin-bottom: 1rem;
            }

            .description {
                display: flex;
                justify-content: start;
                margin-bottom: 1rem;
            }

            .password-form-group {
                width: 100%;

                .password {
                    margin-bottom: 1rem;
                }

                .confirm-password {
                    margin-bottom: 2.75rem;
                }

                .password-format-alert {
                    margin-bottom: 2.75rem;
                }
            }

            .submit-activation-form-btn {
                margin-top: 2.75rem;
                width: 85%;
            }
        }
    }
}
