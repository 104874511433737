.password-eye {
    display: block;
    z-index: 999;
    top: 30%;
    right: 5px;

    .showEye {
        height: 13px;
    }
    svg {
        height: 16px;
    }
}
