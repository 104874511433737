.starting-guide-section-container {
    margin-top: 40px;

    .starting-guide-section {
        display: contents;

        input,
        textarea {
            max-width: 500px;
        }

        .step-title-input {
            display: flex;
            align-items: center;

            input {
                margin-right: 15px;
            }

            .delete-button {
                cursor: pointer;

                svg {
                    width: 14px;
                    height: 16px;
                    fill: $mid-grey-2;
                }
            }
        }

        .add-more-button {
            border: none;
            padding: 0;
            margin-bottom: 20px;
            margin-top: 20px;

            &:hover {
                background-color: transparent;
            }
        }
    }
}
