.organisation-management-page {
    padding: 30px 25px;

    .col-md-8 {
        padding-left: 0;

        .search {
            max-width: 620px;
        }
    }

    .organisation-row {
        width: 100%;
        border-top: 0.5px solid $mid-grey-2;
        border-bottom: 0.5px solid $mid-grey-2;

        .organisation-row-header {
            display: flex;
            height: 100%;
            cursor: pointer;
            background-color: $light-grey-1;
            padding: 15px 0px;
            font-weight: $font-weight-bold;
        }

        svg {
            width: 12px;
            height: 7px;
        }

        .horizontal-align {
            margin: 0 auto;
        }

        .organisation-name {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        .organisation-user-count {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }

    .users-table {
        width: 100%;

        tbody {
            background-color: $white;
        }

        thead {
            border-top: 1px solid $mid-grey-2;
        }

        tr {
            .user-status {
                width: 100px;
            }

            .user-last-login,
            .action-button,
            .user-role {
                width: 150px;
            }
        }

        tr:hover {
            background-color: transparent;
        }

        svg {
            width: 100px;
            height: 100px;
        }

        .user-details {
            label {
                font-weight: $font-weight-bold;
            }
        }
    }
}
