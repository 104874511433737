.installation-icon {
    svg {
        width: $svg-size-large;
        height: $svg-size-large;
    }
}

.td-installation-status {
    padding: 5px 6px;
    border-radius: 4px;
    color: $white;
    width: fit-content;
}

.td-installation-status-installed {
    background-color: $rd-green;
}

.td-installation-status-uninstalled {
    background-color: $mid-grey-1;
}

.td-installation-status-pending {
    background-color: $rd-orange;
}

.td-installation-status-revoked {
    background-color: $rd-red;
}
