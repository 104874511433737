[type='radio']:checked,
[type='radio']:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type='radio']:checked + label,
[type='radio']:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 17px;
    display: inline-block;
    font-weight: $font-weight-bold;
}
[type='radio']:checked + label:before,
[type='radio']:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
}
[type='radio']:checked + label:after,
[type='radio']:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: $rd-web-blue;
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

[type='radio']:checked:disabled + label:after {
    background: $mid-grey-1;
}

[type='radio']:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type='radio']:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.radio-button {
    p {
        white-space: pre;
    }

    .radio-label {
        margin-bottom: 0 !important;
    }
}

.radio-description {
    font-weight: $font-weight-normal;
    margin-top: 2px;
    white-space: pre;
}
