// Global colour variables
$white: #fff;
$black: #000;
$rd-web-blue: #00b7f0;
$rd-pale-blue: #e5f8fd;
$rd-background-blue: #e5e9f0;
$rd-web-highlight: #029dd0;
$rd-orange: #f29151;
$rd-green: #00a099;
$rd-blue: #057cac;
$rd-dark-navy: #032237;
$rd-navy: #054169;
$rd-red: #d11d05;
$rd-black: #474547;
$rd-dark-grey: #252525;
$rd-setting-orange: #d9534f;
$rd-error-red: #d11d02;
$td-type-purple: #8b60bf;
$td-type-blue: #057cac;
$td-type-teal: #00a099;
$danger: $rd-orange;
$danger-background: rgba(242, 145, 81, 0.1);
$unallocated-bookings-section: rgba(209, 29, 5, 0.7);

//booking status colours
$expected: #6bceee;
$late: #ff554c;
$fully-seated: #70b66a;

// Greys
$light-grey-1: #f0f0f0;
$light-grey-2: #f5f5f5;
$light-grey-3: #e5e5e5;

$mid-grey-1: #b2b2b1;
$mid-grey-2: #cbcbcb;

$dark-grey: #6e6e6e;

// DC Colours
$dc-error-red-1: #ff2020;
$dc-error-red-2: #fbd8d0;

// Global variables
$background-color: $light-grey-2;
$input-bg-color: $white;
$button-radius: 3px;
$svg-size-standard: 24px;
$svg-size-large: 100px;

$rd-shadow: 0px 1px 8px 2px #47454722;

// Global Bootstrap Overrides
$font-size-base: 0.8125rem; // 13px
$font-size-lg: 1rem; // 16px
$font-size-sm: 0.6875rem; // 11px
$h1-font-size: 1.875rem; // 30px
$h2-font-size: 1.625rem;
$body-bg: $background-color;
$table-bg: $white;
$btn-box-shadow: none;
$btn-focus-width: 0;
$btn-focus-box-shadow: none;
$btn-active-box-shadow: none;
$input-btn-padding-y: 0.6875rem; // 11px
$input-padding-y: 0.375rem; // 11px
$input-btn-padding-x: 1.25rem; // 20px
$input-padding-x: 0.75rem; // 16px
$input-btn-padding-y-sm: 0.3125rem; // 5px
$input-btn-padding-x-sm: 1.25rem; // 20px
$input-group-addon-border-color: $mid-grey-1;
$input-height: 36px;
$line-height-base: 1.2;
$line-height-sm: 1.2;
$line-height-lg: 1.2;
$font-weight-bold: 700;
$font-weight-normal: 400;
$headings-font-weight: $font-weight-bold;
$sub-headings-font-weight: 200;
$border-radius: $button-radius;

// Button (Background) Colours
$primary: $rd-web-blue;
$secondary: $light-grey-1;
$light: $input-bg-color;
$dark: $dark-grey;
$hub: $rd-navy;

@import 'documentation.scss';

// To disable bootstrap input warning icons
$enable-validation-icons: false;
@import 'bootstrap.scss';

@font-face {
    font-family: 'Open Sans SemiBold';
    src: url('/fonts/OpenSans-SemiBold.ttf');
}

@font-face {
    font-family: 'Open Sans Regular';
    src: url('/fonts/OpenSans-Regular.ttf');
}

@font-face {
    font-family: 'Open Sans Bold';
    src: url('/fonts/OpenSans-Bold.ttf');
}

@font-face {
    font-family: 'PT Mono Bold';
    src: url('/fonts/pt-mono.bold.ttf');
    font-weight: bold;
}
.disabled {
    color: $dark-grey;
}
.rotate-180deg {
    transform: rotate(180deg);
}

.rotate-90deg {
    transform: rotate(90deg);
}

.rotate-270deg {
    transform: rotate(270deg);
}

a {
    cursor: pointer;
}
.small {
    font-size: 90%;
}
main {
    font-family: 'Open Sans Regular', sans-serif;
    font-size: 13px;
    color: $rd-black;
}

body {
    font-family: 'Open Sans Regular', sans-serif;
    font-size: 13px;
    color: $rd-black;
    // background-color: $rd-background-blue;
}

.body-container {
    margin-top: 56px;

    @at-root #{&}__center {
        margin: 10px auto;
    }
}
.brand-svg {
    height: 32px;
    width: 200px;
    flex: 0 0 100%;
}
@media (max-width: 768px) {
    // medium devices. See https://getbootstrap.com/docs/4.1/layout/overview/#responsive-breakpoints
    main {
        margin-top: 99px; // base margin + mobileNavHeader
    }
}

table {
    min-width: 70%;

    thead {
        background-color: $light-grey-1;

        tr {
            th {
                font-family: 'Open Sans Bold', sans-serif;
                font-size: 12px;
                color: $rd-black;
                text-transform: uppercase;
                padding-top: 10px;
                padding-bottom: 10px;
                border-top: none !important;
                border-left: none;
                border-right: none;
                padding-left: 16px;
            }

            th:first-of-type {
                border-top-left-radius: 5px;
            }

            th:last-of-type {
                border-top-right-radius: 5px;
            }
        }
    }

    tbody {
        tr {
            border-bottom: 1px solid $mid-grey-2;

            td {
                padding-top: 10px;
                padding-bottom: 10px;
                border-left: none;
                border-right: none;
                padding-left: 16px;
            }

            td:first-of-type {
                border-left-style: none;
            }
        }

        tr:last-of-type {
            border-bottom-style: none;
        }

        tr:hover {
            background-color: $rd-pale-blue;
        }
    }
}

.btn-primary,
.btn-primary:focus {
    background-color: $rd-web-blue;
    border-color: $rd-web-blue;
    color: $white;
}

.btn-primary:hover {
    background-color: $rd-web-highlight;
    border-color: $rd-web-highlight;
    color: $white;
}

.badge-outline {
    color: black;
    border: 1px solid #999;
    background-color: transparent;
}

.badge-outline.badge-info {
    border-color: #057cac;
    color: #057cac;
}

.badge-secondary {
    background-color: $mid-grey-1;
    color: $white;
}
.dropdown-menu.show {
    width: 100%;
}
.toggle-switch {
    vertical-align: middle;
    margin-left: 15px;

    .toggle-option-label {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        font-size: 13px;
        color: $white;
    }
}

.bold-text {
    font-weight: 700;
    padding-bottom: 10px;
}
.intro-header {
    text-align: left;
    margin-bottom: 30px;
}
.flex-container-center {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

a {
    color: $rd-web-blue;

    &:hover {
        color: $rd-blue;
        text-decoration: underline;
    }
}

.vertical-centre {
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.validation-input-feedback {
    display: flex;
}

.validation-input-count {
    margin-left: auto;
    margin-top: 0.25rem;

    &.warning {
        color: $danger;
    }
}

.input-character-count {
    color: $dark-grey;
    float: right;
    margin-top: 5px;
    font-size: 12px;
}

.validation-message {
    color: $danger;
    font-size: 0.8125rem;
    fill: $danger;
    display: inline-flex;

    svg {
        fill: $danger;
        min-width: 14px;
        width: 0.8125rem;
        height: 0.8125rem;
        align-self: center;
    }

    &.block {
        color: $white;
        background-color: $danger;
        margin-bottom: 5px;
        padding: 5px;
        font-weight: bold;

        svg {
            fill: $white;
            width: 14px;
            height: 14px;
        }
    }

    .validation-message-text {
        align-self: center;
    }
}

// Success Alert
.alert-success {
    background-color: $rd-green;
    color: $white;
}
// Danger Alert
.alert-danger {
    background-color: $rd-orange;
    color: $white;
}

// Warning Alert
.alert-warning {
    background-color: rgba(234, 126, 54, 0.1);
}

.alert-dismissible .close {
    padding: 4px 10px;
}

.alert-rd-info {
    background-color: $light-grey-3;
    border-color: $light-grey-3;
}
// Pagination
.page-item {
    font-weight: bold;

    > .page-link.page-shifter {
        padding: 4px;

        svg {
            width: $svg-size-standard;
            height: $svg-size-standard;
            fill: $dark-grey;
        }
    }

    &.active:hover {
        color: $white;
    }

    &.disabled .page-link {
        svg {
            fill: $pagination-disabled-color;
        }
    }
}

.info-bar-wrapper {
    position: fixed;
    z-index: 1069;
    right: 0;
    left: 0;
    text-align: center;

    .hidden {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s 2s, opacity 2s linear;
    }

    .info-bar {
        display: table;
        margin: 0 auto;
        position: relative;
        font-weight: bold;
        padding: 2px 3px 0px 3px;

        &:not(:first-child) {
            margin-top: 5px;
        }

        div {
            display: inline;
        }

        svg {
            width: $svg-size-standard;
            height: $svg-size-standard;
            fill: $white;
        }

        button {
            color: $rd-black;
        }

        .close {
            margin-left: 5px;
            position: unset;

            span {
                position: relative;
                top: -3px;
            }
        }
    }
}

.content {
    background-color: #fff;
    padding-top: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    min-height: calc(100vh - 56px);
}

.page-header {
    margin-top: 20px;

    h1 {
        font-weight: normal;
    }
}

.form-wrapper {
    width: 100%;
    background-color: $white;
    padding: 20px;
}
select.form-control.is-invalid {
    background-position: right calc(1.2em + 0.1875rem) center !important;
}

.button-link {
    border: none;
    background-color: transparent;
    text-decoration-line: underline;
    color: $rd-web-blue;
}

.credential-input-group {
    flex-wrap: nowrap;
    height: 36px;

    input:read-only {
        background-color: $white;
        border-color: $mid-grey-1;
    }

    .credential-input {
        position: relative;
        display: inline-block;
        width: 85%;
    }

    svg {
        position: absolute;
        bottom: 10px;
        right: 10px;
    }
}

.svg-large {
    height: $svg-size-large;
    width: $svg-size-large;
}

.clickable {
    cursor: pointer;
}
.beta {
    background-color: $rd-navy;
    color: #fff;
    border-radius: 15px;
    padding: 0.2rem 0.5rem;
    margin: 0 0.5rem;
    font-size: 0.7em;
    letter-spacing: 0.1rem;
}

@import 'button.scss';
@import 'data-table.scss';
@import 'pages/account.scss';
@import 'pages/settings.scss';
@import 'pages/log-list.scss';
@import 'pages/home.scss';
@import 'pages/error.scss';
@import 'pages/landing.scss';
@import 'pages/profile-team-members.scss';
@import 'roleAssignment.scss';
@import 'pages/test-diary.scss';
@import 'pages/certification.scss';
@import 'sidebar.scss';
@import 'api-doc.scss';
@import 'code.scss';
@import 'mobile-nav.scss';
@import 'loadingSpinner.scss';
@import 'header.scss';
@import 'progress.scss';
@import 'rd-radio-checkbox.scss';
@import 'toggle-password-visibility.scss';
@import 'navBar.scss';
@import 'radio-button.scss';
@import 'appManagement.scss';
@import 'tag.scss';
@import 'button-group.scss';
@import 'progressTracking.scss';
@import 'input-group.scss';
@import 'checkBoxGroup.scss';
@import 'imageUpload.scss';
@import 'collapsible.scss';
@import 'search.scss';
@import 'pages/user-management.scss';
@import 'pages/audit-trail.scss';
@import 'pages/webhooks-config.scss';
@import 'pages/organisation-management.scss';
@import 'pages/api-logs-details.scss';
@import 'revokeModal.scss';
@import 'pages/app-starting-guide.scss';
@import 'rich-text-editor.scss';
@import 'pages/installations.scss';
@import 'tooltip.scss';
@import 'pages/appSubmissionApprovalModal.scss';
@import 'pages/rolesPage.scss';
@import 'pages/activateAccount.scss';
@import 'pages/loginPage.scss';
@import 'toast.scss';
