.custom-control-label::before {
    border: 1px solid $mid-grey-1;
}

.custom-control-cursor-pointer .custom-control-label:hover {
    cursor: pointer !important;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    border: 1px solid #029dd0;
}

.custom-checkbox .custom-control-input:checked:disabled ~ .custom-control-label::before {
    background-color: $mid-grey-1;
    border: 1px solid $mid-grey-2;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-color: $rd-web-blue;
    top: 5px;
    left: -23px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-image: none;
}

.custom-radio .custom-control-label::before {
    top: 0;
    left: -28px;
    display: block;
    width: 20px;
    height: 20px;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
    background-color: $white;
    color: $rd-web-blue;
}
