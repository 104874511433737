svg {
    height: $svg-size-standard;
    width: $svg-size-standard;
    fill: $dark-grey;
}

.text-with-icon {
    .text {
        padding-left: 5px;
        align-self: center;

        &.header-text {
            font-size: $h1-font-size;
        }

        &.extra-padding {
            padding-left: 10px;
        }

        &.inactive {
            font-weight: bolder;
            color: $mid-grey-1;
        }
    }

    .icon {
        &.fully-seated {
            svg {
                fill: $fully-seated;
            }
        }

        &.expected {
            svg {
                fill: $expected;
            }
        }

        &.late {
            svg {
                fill: $late;
            }
        }

        &.table-icon {
            svg {
                fill: $rd-orange;
            }
        }

        &.calender-icon {
            svg {
                fill: $rd-navy;
            }
        }

        &.person-icon {
            svg {
                fill: $rd-blue;
            }
        }

        &.credit-card-icon {
            svg {
                fill: $fully-seated;
            }
        }

        &.management-icon {
            svg {
                fill: $rd-setting-orange;
            }
        }

        &.extra-padding {
            svg {
                width: 18px;
                height: 18px;
            }
        }
    }
}

.horizontal-splitter {
    width: 50px;
    height: 1px;
    background-color: $mid-grey-1;
    margin-right: 10px;
    margin-left: 10px;

    &.mob {
        width: 20px;
    }

    &.modal-mob {
        width: 5px;
    }
}

.rd-progress-bar {
    justify-content: center;
    align-items: center;
    margin: 10px 0px 5px 0px;
}

.progress-icon-container {
    border: 1px solid $rd-web-blue;
    border-radius: 100px;
    background: $rd-web-blue;
    padding-left: 1px;
    padding-top: 1px;

    svg {
        fill: white;
    }

    &.incomplete {
        background: white;
        border-color: $rd-web-blue;

        svg {
            fill: $rd-web-blue;
        }
    }

    &.inprogress {
        background: white;
        border-color: $rd-web-blue;

        svg {
            fill: $rd-web-blue;
        }
    }

    &.not-started {
        background: white;
        border-color: $rd-web-blue;

        svg {
            fill: white;
        }
    }

    &.success-icon {
        background: $rd-green;
        border: $rd-green;
        width: fit-content;
        margin: 0 auto;

        svg {
            fill: $white;
            height: 50px;
            width: 50px;
        }
    }
}

.info-bar-wrapper {
    position: inherit;
    z-index: 1075;
    right: 0;
    left: 0;
    top: 190px;
    text-align: center;
    max-width: 35%;
    margin: 0 auto;

    &.tablet {
        max-width: 50%;
    }

    &.mobile {
        max-width: 75%;
    }

    .hidden {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s 2s, opacity 2s linear;
    }

    .info-bar {
        display: table;
        margin: 0 auto;
        position: relative;
        font-weight: $font-weight-bold;
        padding: 2px 3px 0px 3px;

        &:not(:first-child) {
            margin-top: 5px;
        }

        div {
            display: inline;
        }

        svg {
            fill: $white;
        }

        button {
            color: $rd-black;
        }

        .close {
            margin-left: 5px;
            position: unset;

            span {
                position: relative;
                top: -3px;
            }
        }
    }
}
