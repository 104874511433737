.search {
    svg {
        width: 25px;
        padding: 8px 0px 0px 3px;
    }

    .input-group > .form-control:not(:first-child),
    .input-group > .custom-select:not(:first-child) {
        border-left: none;
    }

    .input-group > .form-control:not(:first-child) :focus-within,
    .input-group > .custom-select:not(:first-child) {
        border-left: none;
    }

    .input-group-prepend {
        border: solid 0.1em #ccc;
        border-radius: 3px 0 0 3px;
    }

    .input-group:focus-within .input-group-prepend {
        border: solid 0.1em #71ddff;
        outline: 0;
        box-shadow: 0 0 0 0.2rem #bfedfb;
    }

    .input-group .form-control:focus {
        border-left: none;
        clip-path: inset(-5px -5px -5px 0px);
    }
}
