.collapsible {
    .header {
        cursor: pointer;
        background-color: $light-grey-1;
        padding: 10px 0px;
        font-weight: bold;
        border-bottom: 2px $mid-grey-2 solid;
    }

    .header:hover {
        background-color: $mid-grey-2;
    }

    .collapsible-content-row {
        padding: 1rem 0;
        border-bottom: solid #ccc 1px;
    }

    .collapsible-content-row:hover {
        background-color: $rd-pale-blue;
    }
    .form-check-label {
        margin-top: 0.2rem;
    }
    .content-section {
        padding-left: 1rem;
    }
}
