.app-management-page {
    svg.logo {
        width: 3rem;
        height: 3rem;
    }
}

.app-management-card {
    height: 100%;
    border: 1px solid $mid-grey-2;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0 2px 8px 0 $mid-grey-2;

    &:hover {
        cursor: pointer;
        border: 1px solid $rd-web-highlight;
        transform: scale(1.01);
    }

    h4 {
        color: $rd-navy;
        margin: 0px;
    }

    .icon-container {
        &:has(svg) {
            background-color: $rd-pale-blue;
        }

        svg {
            height: 160px;
            width: auto;
        }

        img {
            height: 160px;
            width: auto;
        }
    }

    .created-by {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}

.app-icon-container {
    width: 100px;
    height: 100px;
    margin-right: 20px;

    svg {
        width: 100px;
        height: 100px;
    }

    img {
        width: 100px;
        height: 100px;
    }
}

.app-navigation-page {
    width: 100%;

    & > .info-bar-wrapper {
        top: inherit !important;
    }

    .app-navigation-header {
        height: 168px;
        width: 100%;
        background-color: $rd-navy;
        background-size: cover;
        padding: 20px;
        color: white;

        &.admin {
            background-color: $dark-grey;
        }
        h1 {
            color: white;
        }

        .app-info {
            .rd-tag {
                margin-right: 10px;
            }

            .app-id-container {
                margin: 5px 0 5px 0;

                svg {
                    height: 12px;
                    width: 12px;
                    margin-left: 5px;
                    fill: white;
                }

                &:hover {
                    cursor: pointer;
                }
            }
        }

        .back-icon {
            cursor: pointer;

            .back-label {
                font-weight: bold;
            }

            svg {
                height: 14px;
                width: 16px;
                fill: white;
                margin-right: 5px;
            }
        }
    }

    .webhooks-secret-label-icon {
        svg {
            height: 17px;
            width: 17px;
        }
    }
}

.create-app-modal {
    .modal-title {
        font-size: $h2-font-size;
        font-weight: $font-weight-normal;
    }

    .modal-introduction {
        font-size: $font-size-lg;
        font-weight: $sub-headings-font-weight;
    }

    .modal-button-group {
        display: flex;
        justify-content: flex-end;
    }

    .info-bar-wrapper .info-bar {
        margin: 84px auto;
    }
}

.get-started-page {
    width: 70%;

    .section {
        height: 76px;
        width: 100%;
        margin-bottom: 15px;
        border: 1px solid $mid-grey-2;
        border-radius: 8px;
        padding: 15px;
        display: flex;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.09);

        &.completed {
            border: none;
            background-color: $light-grey-2;
        }

        .icon-container {
            margin-right: 10px;

            .progress-icon-container {
                padding-top: 0;

                svg {
                    width: 16px;
                    height: 16px;
                }
            }
        }

        .section-title {
            font-weight: bold;
            margin-bottom: 10px;
        }

        .arrow-container {
            margin-left: auto;
            align-self: center;

            svg {
                height: 16px;
                width: 16px;
                fill: $rd-web-blue !important;
            }
        }

        &:hover {
            cursor: pointer;
            background-color: $rd-pale-blue;
        }
    }
}

.authentication-page {
    a.open-link {
        text-decoration: none !important;

        i {
            vertical-align: text-top;
        }

        i > svg {
            height: 12px;
            width: 12px;
        }
    }

    table.scope-list {
        width: 100%;
        background-color: #f5f5f5;
        border-radius: 5px;

        td {
            padding-top: 5px;
            padding-bottom: 5px;
        }

        td.scope-input {
            text-align: center;
        }
    }
}

.submission-page {
    @at-root #{&}-info-section {
        svg {
            height: 16px !important;
            width: 16px !important;
            margin-right: 5px;
            margin-top: -2px;
        }

        .submitted {
            p {
                margin-left: 21px;
            }
        }

        .approved {
            svg {
                border-radius: 50%;
                background-color: $td-type-teal;
                fill: white;
            }

            color: $td-type-teal;
        }

        .rejected {
            .alert-warning {
                h6 {
                    svg {
                        fill: #ea7e36;
                    }

                    color: #ea7e36;
                }

                p {
                    color: $rd-black !important;
                    margin-left: 21px;
                }
            }
        }
    }

    .video-category-list {
        padding: 0px;
        margin-bottom: 20px;

        .video-list-item {
            display: flex;
            padding: 0px;

            .form-group {
                margin-bottom: 0px;
            }

            .video-title {
                padding: 0px 10px 0px 0px;
            }

            .video-url {
                padding: 0px;
            }

            .action-buttons {
                .btn-delete {
                    margin-top: 20px;
                    border: 0px;
                    background-color: transparent;
                    height: 36px;
                    width: 36px;

                    svg {
                        height: 16px;
                        width: 14px;
                    }

                    &.disabled {
                        svg {
                            fill: $mid-grey-2;
                        }
                    }
                }
            }
        }

        .btn-add {
            padding: 0px;
            font-weight: bold;
            border: none;
            background-color: transparent !important;

            &.disabled {
                color: $dark-grey !important;
            }
        }
    }

    .listing-live {
        color: $rd-green;
    }

    .disable-editor > div {
        pointer-events: none;
        background-color: #e9ecef;
        opacity: 1;
    }
}

.app-list-base-page {
    width: 100%;
}

.app-submission-logs {
    th {
        &.date,
        &.update-by {
            width: 15%;
        }

        &.remarks,
        &.comments {
            width: 30%;
        }
    }

    .rd-tag {
        white-space: nowrap;
    }
}

#info-icon {
    height: 15px;
}

.environment-dropdown {
    .btn-secondary {
        background: transparent;
        color: #ffffff;
        padding: 0 0 0 0;
        margin-left: -16px;
        font-size: 16px;
        margin-top: -3px;
        border: none;
    }

    .btn-secondary:not(:disabled):not(.disabled):hover {
        color: #ffffff;
        border: none;
        background-color: transparent;
    }

    .btn-secondary.dropdown-toggle {
        border: none;
        background-color: transparent;
        color: #ffffff;
    }

    .dropdown-header {
        font-family: 'Open Sans';
        font-size: 14px;
        color: #4e4e4e;
    }

    .dropdown-item {
        font-family: 'Open Sans';
        line-height: 33px;
        width: 230px;
        font-size: 15px;

        &.active {
            color: #029dd0;
            background-color: transparent;
            border-left: 4px solid;
        }
    }
}

.client-secret-card {
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem 1.5rem;
    width: 30rem;

    .secret-box {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 27.75rem;
        height: 5rem;
        margin-bottom: 1rem;

        .secret-section {
            padding: 0.75rem;
            width: 24rem;
            word-wrap: break-word;
            background-color: $light-grey-3;
            height: 6rem;
        }
    }
}

.client-secret-modal {
    width: 30rem;

    .client-secret-warning {
        margin-bottom: 2rem;
    }

    .client-secret-string {
        padding: 1rem;
        margin-bottom: 2rem;
        word-wrap: break-word;
    }
}

.client-secret-warning-modal {
    width: 29rem;

    .title {
        svg {
            height: 1rem;
        }
    }
}

.oauth-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem 1rem 1.5rem;
    width: 30rem;
}

.test-diaries-tab {
    .header {
        padding-bottom: 1.5rem;
    }
    .table {
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-right: 1rem;
    }
}
