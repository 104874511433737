.user-details {
    div.section {
        padding-right: 15px;
        vertical-align: middle;
    }
}

.profile-icon {
    padding-right: 5px;
    width: 15px;
    height: 15px;
    transform: translateY(-1px);
}
