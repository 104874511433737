.progress-tracker {
    display: flex;
    white-space: nowrap;
    align-items: center;
    width: 100%;

    .progress {
        width: 100%;
        height: 6px;

        .progress-bar {
            background: linear-gradient(270deg, rgba(5, 124, 172, 1) 0%, rgba(12, 179, 214, 1) 100%);
        }
    }
}

.progress-value {
    display: flex;
    margin-right: 10px;
}
