.docs-page {
    margin-top: 61px;
}

.attributes {
    border-radius: 3px;
    padding-left: 5px;
}

.attributes--expandable {
    border: 1px solid #00b7f0;

    li {
        padding-left: 8px;
    }

    li:first-of-type {
        border-top-style: none !important;
    }

    li:last-of-type {
        border-bottom-style: none !important;
    }
}

.api-doc {
    padding-bottom: 30px;

    h2 {
        font-family: 'Open Sans SemiBold', sans-serif;
        font-size: 30px;
        color: #474547;
        border-top: 1px solid #cbcbcb;
        padding-top: 30px;
        padding-bottom: 20px;
        margin-top: 30px;
    }

    ul {
        padding-left: 18px;
    }

    @at-root #{&}__verb-path-container {
        position: relative;
        margin-bottom: 15px;
    }

    @at-root #{&}__verb {
        font-family: 'Open Sans SemiBold', sans-serif;
        font-size: 12px;
        line-height: 24px;
        color: white;
        background-color: #054169;
        border-radius: 3px;
        position: absolute;
        height: 24px;
        width: 70px;
        text-align: center;
        top: 50%;
        transform: translateY(-50%);
    }

    @at-root #{&}__path {
        font-family: 'Open Sans SemiBold', sans-serif;
        font-size: 13px;
        color: #054169;
        word-wrap: break-word;
        margin-left: 80px;
        width: calc(100% - 80px);
    }

    @at-root #{&}__name {
        font-family: 'Open Sans SemiBold', sans-serif;
        font-size: 28px;
        color: #474547;
        padding-bottom: 15px;
    }

    @at-root #{&}__description {
        font-family: 'Open Sans Regular', sans-serif;
        font-size: 13px;
        padding-bottom: 10px;
    }

    .attribute__required {
        font-family: 'Open Sans Regular', sans-serif;
        font-size: 10px;
        font-weight: bold;
        margin-left: 8px;
        color: $rd-orange;
    }

    .attribute__optional {
        font-family: 'Open Sans Regular', sans-serif;
        font-size: 10px;
        font-weight: bold;
        margin-left: 8px;
        color: $rd-blue;
    }

    ul.attributes {
        list-style-type: none;
        padding: 0;

        li:first-of-type {
            border-top: 0.5px solid #cbcbcb;
        }
    }

    div:nth-of-type(3) {
        padding-top: 15px;
    }
}

.section {
    @at-root #{&}__name {
        font-family: 'Open Sans Regular', sans-serif;
        font-size: 22px;
        padding-bottom: 8px;
        font-weight: 500;
    }
}

.toggle-container {
    padding-right: 10px;

    @at-root #{&}__link {
        font-family: 'Open Sans SemiBold', sans-serif;
        font-size: 13px;
        color: #029dd0;
        cursor: pointer;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

.section--response {
    & > ul.attributes {
        margin-bottom: 0;
    }
}

.attribute {
    border-bottom: 0.5px solid #cbcbcb;
    padding-top: 12px;
    padding-bottom: 12px;

    @at-root #{&}__name {
        font-family: 'PT Mono Bold', sans-serif;
        font-size: 13px;
        color: #474547;
    }

    @at-root #{&}__type {
        font-family: 'Open Sans SemiBold', sans-serif;
        font-size: 12px;
        color: #b2b2b1;
        margin-left: 8px;
    }

    @at-root #{&}__description {
        font-family: 'Open Sans Regular', sans-serif;
        font-size: 13px;
        color: #474547;
        padding-top: 5px;
        padding-bottom: 5px;
    }
}

a.hash-link {
    visibility: hidden;
    color: #029dd0;
}

*:hover > a.hash-link {
    visibility: visible;
    color: #029dd0;
}

.anchor-id {
    display: block;
    position: relative;
    top: -100px;
    visibility: hidden;
}
