.data-table {
    width: 100%;
}

.header-cell-elements {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
}

.col-name {
    padding-top: 3px;
}

.data-list {
    @at-root #{&}__filters {
        padding-top: 10px;
        padding-bottom: 10px;
        height: 60px;

        label {
            margin-right: 10px;
        }
    }

    @at-root #{&}__no-results {
        text-align: center;
    }

    @at-root #{&}__pagination {
        display: block;
        text-align: center;
        padding-top: 30px;
        padding-bottom: 30px;

        @at-root #{&}__button {
            font-family: 'Open Sans SemiBold', sans-serif;
            font-size: 12px;
            color: #057cac;
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 24px;
            padding-right: 24px;
            border: 1px solid #00b7f0;
            box-sizing: border-box;
            border-radius: 3px;
        }
    }
}
