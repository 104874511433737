.introduction {
  font-family: "Open Sans Regular", sans-serif;
  font-size: 13px;
  color: #474547;
}

.explore-our-products {
  font-family: "Open Sans SemiBold", sans-serif;
  font-size: 13px;
  color: #474547;
  padding-bottom: 10px;
}

.product-name {
  display: inline-block;
  border: 1px solid #029dd0;
  border-radius: 3px;
  margin-bottom: 10px;
  width: 100%;
  height: 40px;
  line-height: 36px;
  text-align: center;
  color: #029dd0;
  font-family: "Open Sans SemiBold", sans-serif;
  text-decoration: none !important;
}
