.login-page {
    margin: 0 auto;

    .logo {
        display: flex;
        justify-content: center;
        margin-bottom: 2rem;

        svg {
            width: 11rem;
        }
        .beta {
            margin: 2px !important;
        }
    }

    .content-card {
        width: 37rem;
        background-color: $white;
        padding: 2.5rem 4.5rem;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
        border-radius: 0.25rem;

        .login-form {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .login-form-group {
                width: 100%;

                .username {
                    margin-top: 1.5rem;
                }

                .password {
                    margin-top: 1.5rem;
                }

                .submit {
                    margin-top: 1.5rem;
                    margin-bottom: 1.5rem;
                }
            }

            .incorrect-details-error {
                width: 100%;
                margin-top: 1.5rem;
                display: flex;
                justify-content: center;
            }
        }
    }
}
