h1 {
    /* Level 1 heading: main headings (page title) */
    font-size: 32px;
    font-weight: 500;
    letter-spacing: -0.75px;
    line-height: 45px;
    color: #4e4e4e;
}

h2 {
    /* Level 2 headings: subheadings.Modal title */
    font-size: 25px;
    font-weight: 500;
    letter-spacing: -0.75px;
    line-height: 32px;
    color: #4e4e4e;
}

h3 {
    /* Level 3 headings: sub-subheadings (headings under subheadings). */
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.75px;
    line-height: 32px;
    color: #4e4e4e;
}

h4 {
    /* Level 4 headings: headings under level 3 headings */
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.5px;
    line-height: 26px;
    color: #4e4e4e;
}

h5 {
    /* Level 5 headings: headings under level 4 headings */
    font-size: 15px;
    font-weight: 500;
    letter-spacing: -0.5px;
    line-height: 24px;
}

/* Body-normal Body-medium Body-bold  Body text Button text, text links Text fields, Dropdown fields Error messages Tooltips Checkboxes,
Radio buttons Tags Status labels Steppers Navigation text */
// body-normal
p {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.25px;
    line-height: 18px;
    color: #4e4e4e;
}

body-medium {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.25px;
    line-height: 18px;
}

body-bold {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: -0.25px;
    line-height: 18px;
}

smallBody-caps-bold {
    /* Table header text, section title */
    font-size: 12px;
    font-weight: 700;
    letter-spacing: -0.25px;
    line-height: 16px;
}

/* Disclaimer text */
smallBody-normal {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: -0.25px;
    line-height: 16px;
}

smallBody-meduim {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.25px;
    line-height: 16px;
}

smallBody-bold {
    font-size: 12px;
    font-weight: 700;
    letter-spacing: -0.25px;
    line-height: 16px;
}
