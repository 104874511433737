.revoke-modal {
    .modal-content {
        .modal-header {
            .icon {
                .svg {
                    fill: red;
                }
            }
        }

        input[name='revoke-input'] {
            max-width: 300px;
        }

        .modal-footer {
            .btn-danger {
                background-color: $rd-error-red;
                color: white;
            }

            .btn-danger:disabled {
                opacity: 0.4;
            }
        }
    }
}
