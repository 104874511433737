.rd-tag {
    color: $white;
    border-radius: 3px;
    text-align: center;
    padding: 5px 15px;
    min-width: 85px;
    display: block;
    max-height: 24px;
    text-transform: uppercase;
    width: 100%;

    &.navy {
        background-color: $rd-navy;
    }

    &.green {
        background-color: $rd-green;
    }

    &.red {
        background-color: $rd-red;
    }

    &.orange {
        background-color: $rd-orange;
    }

    &.blue {
        background-color: $rd-web-highlight;
    }

    &.darkblue {
        background-color: $rd-blue;
    }

    &.purple {
        background-color: $td-type-purple;
    }

    &.lightgreen {
        background-color: #99d9d6;
    }

    &.grey,
    &.disabled {
        background-color: $mid-grey-1;
    }
}
