.account-page {
    @at-root #{&}__body {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);

        @at-root #{&}__logo {
            padding: 2rem 0;
        }
    }

    .form-group {
        min-width: 100px;
        width: 100%;
    }

    .alert-container {
        margin-top: 10px;

        .alert-success {
            background-color: $rd-green;
            color: $white;
        }

        .alert-danger {
            background-color: $rd-red;
            color: $white;
        }

        svg {
            height: 25px;
            width: 25px;
            fill: $white;
        }
    }
}

.form-content {
    width: 600px;
    padding: 20px;
    margin: 0 auto !important;

    label {
        float: left;
    }
}

.account-form {
    min-width: 600px;
    background-color: white;
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 50px;
    padding-bottom: 50px;
    border-radius: 3px;
    box-shadow: 0 4px 12px 12px rgba(24, 36, 43, 0.04), 0 2px 4px 0 rgba(0, 0, 0, 0.07);

    @at-root #{&}__content {
        max-width: 600px;
        margin: auto;
    }

    @at-root #{&}__title {
        font-family: 'Open Sans Regular', sans-serif;
        font-size: 28px;
        color: #474547;
        text-align: center;
    }

    &--fixed-width {
        @extend .account-form;
        max-width: 600px;
        margin: auto;
    }

    @at-root #{&}__group {
        label {
            display: block;
            font-family: 'Open Sans Regular', sans-serif;
            font-size: 13px;
            color: #474547;
        }
    }

    @at-root #{&}__success {
        background-color: $rd-green;
        color: white;
        font-family: 'Open Sans SemiBold', sans-serif;
        font-size: 12px;
        padding-top: 6px;
        padding-bottom: 6px;
        padding-left: 10px;
        margin-top: 10px;
        margin-bottom: 10px;

        img {
            transform: translate(-2px, -2px);
            padding-right: 2px;
        }
    }

    @at-root #{&}__error {
        background-color: $dc-error-red-2;
        color: $black;
        padding-left: 10px;
        margin-top: 1rem;
        font-weight: 600;
        border: 1px solid $dc-error-red-1;

        svg {
            min-width: 14px;
            width: 1.2rem;
            height: 1.2rem;
            align-self: center;
            margin-right: 0.3rem;
            fill: $dc-error-red-1;
        }

        a {
            color: $dc-error-red-1;
        }
    }

    .form-group {
        margin-top: 1rem;
        margin-bottom: 1rem;

        &.form-group--double-margin {
            margin-top: 2rem;
        }
    }

    input::-webkit-input-placeholder,
    input:-ms-input-placeholder,
    input::placeholder {
        color: #b2b2b1;
        font-family: 'Open Sans Regular', sans-serif;
        font-size: 12px;
    }

    .header-icon {
        width: 50px;
        margin: auto;
    }

    .header-alert {
        svg {
            color: grey;
            width: 50px;
            height: 50px;
        }
    }

    .head-success {
        svg {
            color: $rd-green;
        }
    }

    .input-group-checkbox {
        width: 0px !important;
    }
}

.account-questionnaire {
    width: 500px;
    display: inline-table;
    text-align: left;

    ol {
        padding-left: 15px;

        .questionnaire-item {
            font-weight: bold;

            .selection-container {
                padding-top: 10px;
                padding-bottom: 10px;
                font-weight: normal;

                .selection-item {
                    display: grid;
                }

                .sub-question {
                    padding-left: 25px;
                    margin-top: 0px;
                    margin-bottom: 10px;
                }
            }
        }
    }

    .btn-company-size.active,
    .btn-company-size:hover {
        background-color: #e5f8fd !important;
    }

    .btn-company-size {
        background-color: #fff;
        border-radius: 5px !important;
        border-color: $rd-web-blue !important;
        margin-right: 5px;
        width: 100px;
        height: 95px;
        padding: 10px;

        p {
            margin: 0px;
            line-height: 2;
        }

        .employee-count {
            color: $rd-web-highlight;
            font-weight: bold;
        }
    }

    .btn-submit {
        width: 50%;
        float: right;
    }
}

.forgot-password-button {
    margin-left: auto;
    padding: 0;
    margin-bottom: 0.5rem;
}
