.btn-light {
    border-color: $mid-grey-1;
    background-color: $white;
    color: $rd-black;
    padding: 6px 12px;

    &:focus {
        background-color: $white;
        border-color: $mid-grey-1;
    }

    &:hover {
        border-color: $mid-grey-1;
        background-color: $light-grey-1;
        color: $rd-black;
    }
}

.btn.disabled,
.btn:disabled {
    cursor: default;
}

.btn-light.disabled,
.btn-light:disabled {
    border-color: $mid-grey-1;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
    background-color: $white;
    border-color: $mid-grey-1;
}

.btn-dark,
.btn-dark:hover,
.btn-dark:focus,
.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
    background-color: $rd-navy;
    color: $white;
}

.btn-grey,
.btn-grey:hover,
.btn-grey:not(:disabled):not(.disabled):active,
.btn-grey:not(:disabled):not(.disabled).active,
.show > .btn-grey.dropdown-toggle {
    background-color: $light-grey-2;
    color: $rd-black;
    padding: 6px 12px;
    border-color: $mid-grey-1;
}

button:focus {
    outline: none;
}

button.close-button {
    padding: 0px;
    float: right;
    margin-right: -16px;
    margin-top: -14px;
    background-color: transparent;
    border: none;

    &:hover {
        cursor: pointer;
        background-color: transparent;
    }
}

.btn-outline-primary {
    background-color: $white;
    border-color: $rd-web-blue;
    color: $rd-web-blue;

    &:focus {
        border-color: $rd-web-blue;
        color: $rd-web-blue;
        background-color: $white;
    }

    &:hover {
        color: $rd-web-highlight;
        background-color: $rd-pale-blue;
    }

    &.disabled {
        background-color: $light-grey-2;
    }
}

.btn-secondary {
    color: $rd-web-blue;
    background-color: $white;
    border-color: $rd-web-blue;
}

.btn-secondary:hover,
.btn-secondary:active {
    color: $rd-web-blue;
    background-color: $light-grey-1;
    border-color: $rd-web-blue;
}

.btn-secondary:focus,
.btn-secondary.focus {
    color: $rd-web-blue;
    background-color: $white;
    border-color: $rd-web-blue;
}

.btn-secondary-upload {
    min-width: 170px;
}

.btn-secondary-upload,
.show > .btn-secondary-upload {
    &,
    &.dropdown-toggle {
        background-color: $light;
        color: $primary;
        border-color: $primary;

        &:hover,
        &:active {
            background-color: $rd-pale-blue;
            color: $primary;
            border-color: $primary;
        }

        &.disabled {
            background-color: transparent;
            color: $mid-grey-2;
            border-color: $mid-grey-2;
        }
    }
}
.btn-outline-modal {
    background-color: $white;
    border-color: $white;
    color: $rd-navy;

    &:focus {
        color: $rd-web-blue;
        background-color: $white;
        border-color: $white;
    }

    &:hover {
        color: $rd-web-highlight;
        border-color: $white;
        background-color: $white !important;
    }

    &.disabled {
        background-color: $light-grey-2;
    }
}

.btn-outline-primary.disabled:hover,
.btn-outline-primary:disabled:hover {
    color: $rd-web-blue;
    background-color: transparent;
}

.btn-secondary:not(:disabled):not(.disabled):hover,
.btn-secondary:not(:disabled):not(.disabled).hover {
    background-color: $light-grey-1;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active {
    background-color: $white;
}

.btn-primary.disabled:hover,
.btn-primary:disabled:hover {
    color: $rd-web-blue;
    background-color: transparent;
}

.btn-primary:not(:disabled):not(.disabled):hover,
.btn-primary:not(:disabled):not(.disabled).hover {
    background-color: $rd-blue;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active {
    background-color: $rd-web-blue;
}

.btn-primary.disabled,
.btn-primary:disabled {
    background-color: $mid-grey-2;
    border-color: $mid-grey-1;
}

.btn-primary.disabled:hover,
.btn-primary:disabled:hover {
    color: $white !important;
    background-color: $mid-grey-2 !important;
    border-color: $mid-grey-1 !important;
}

.btn-secondary.disabled:hover,
.btn-secondary:disabled:hover {
    color: $rd-black;
    background-color: $light-grey-1 !important;
    border-color: $light-grey-1 !important;
}

.btn-group-vertical {
    width: 100%;
}

.btn-dashed {
    border: 1px dashed $rd-navy;
    background-color: $light-grey-1;
    color: $rd-navy;

    &:hover:not([disabled]) {
        background-color: $light-grey-3;
        text-decoration: underline;
    }
}

.btn-hub {
    border-color: $hub;
    background-color: $hub;
    color: $white;

    &.btn-hub--blue-outline {
        border: 1px solid $rd-web-blue;
    }

    &:hover {
        color: $rd-web-highlight;
        background-color: $rd-pale-blue;
    }

    &[disabled] {
        &:hover {
            background-color: $hub;
            color: $white;
        }
    }
}

.btn-link {
    &.btn-link--hub {
        color: $hub;

        &:hover {
            color: $rd-web-highlight;
        }
    }
}

.btn-Navy {
    background-color: $rd-navy;
    color: $white;
}

.btn-Green {
    background-color: $rd-green;
    color: $white;
}

.btn-Red {
    background-color: $rd-red;
    color: $white;
}

.btn-Orange {
    background-color: $rd-orange;
    color: $white;
}

.btn-Blue {
    background-color: $rd-web-highlight;
    color: $white;
}

.btn-Darkblue {
    background-color: $rd-blue;
    color: $white;
}

.btn-Purple {
    background-color: $td-type-purple;
    color: $white;
}

.btn-Lightgreen {
    background-color: #99d9d6;
    color: $white;
}

.btn-Grey {
    background-color: $mid-grey-1;
    color: $white;
}
