.error-page {
  @at-root #{&}__status-code {
    font-family: "Open Sans Bold", sans-serif;
    font-size: 12px;
    font-weight: bold;
    color: #474547;
    margin-top: 17px;
  }

  @at-root #{&}__title {
    font-family: "Open Sans SemiBold", sans-serif;
    font-size: 30px;
    font-weight: 600;
    color: #474547;
    margin-top: 5px;
  }

  @at-root #{&}__description {
    font-family: "Open Sans Regular", sans-serif;
    font-size: 13px;
    line-height: 18px;
    color: #474547;
    margin-top: 17px;
  }
}
