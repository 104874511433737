.user-management-page {
    padding: 30px 25px;

    .col-md-8 {
        padding-left: 0;

        .search {
            max-width: 620px;
        }
    }

    .users-table {
        width: 100%;

        tr {
            .user-status {
                width: 100px;
            }

            .user-last-login,
            .action-button {
                width: 150px;
            }
        }

        tr:hover {
            background-color: transparent;
        }

        svg {
            width: 100px;
            height: 100px;
        }
    }
}

.warning-icon {
    svg {
        fill: $danger;
    }
}
