.upload-image-wrapper {
    display: flex;
    justify-content: center;
    width: 180px;
    height: 180px;
    border: 1px dashed $rd-blue;
    border-radius: 4px;

    img {
        width: 178px;
        height: 178px;
    }
}

.image-item {
    display: inline-block;
    position: relative;

    .upload-new-image-button {
        position: absolute;
        bottom: 70px;
        right: 10px;
        border-radius: 5px;
        display: none;
        width: 160px;
        -webkit-transition: ease-out 0.4s;
        -moz-transition: ease-out 0.4s;
        transition: ease-out 0.4s;
        z-index: 2;
    }

    &:hover {
        img {
            filter: brightness(0.5);
        }
    }

    img:hover + .upload-new-image-button {
        display: inline-block;
    }

    &:hover {
        cursor: pointer;
    }

    .upload-new-image-button:hover {
        display: inline-block;
    }

    .upload-icon-container {
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        text-align: center;
        height: 100%;

        svg {
            margin-bottom: 5px;
            width: 46px;
            height: 38px;
        }
    }

    .progress-container {
        display: flex;
        align-items: center;
        width: 80%;
        flex-direction: column;
        height: 100%;
        justify-content: center;

        .progress {
            width: 100%;
        }
    }
}

.image-validation-message {
    margin-top: 10px;

    .validation-message {
        svg {
            width: 24px;
            height: 24px;
        }
    }
}

.multiple-image-item {
    display: inline-block;
    position: relative;
    padding: 5px;

    .delete-image-button {
        position: absolute;
        bottom: 70px;
        right: 10px;
        border-radius: 5px;
        width: 10px;
        z-index: 2;
        svg {
            transform: translateX(-10px);
        }
    }

    &:hover {
        cursor: pointer;
    }

    .delete-image-button:hover {
        display: inline-block;
    }
}

.multiple-upload-icon-container {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    width: 180px;
    height: 125px;
    border: 1px dashed $rd-blue;
    border-radius: 4px;
    margin: 5px;

    svg {
        margin-bottom: 5px;
        width: 46px;
        height: 38px;
    }
}
