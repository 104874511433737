.app-sidebar {
    width: 100%;
    max-width: 360px;
    background-color: transparent !important;
    font-weight: bolder !important;
    
    .MuiTypography-root {
        font-family: 'Open Sans Regular', sans-serif !important;
        font-weight: bold !important;
        font-size: 13px;
    }

    .nav-menu-item {
        svg {
            height: 18px !important;
        }
    }

    .nav-menu-item.Mui-selected {
        .toggle-icon {
            color: #474547 !important;
        }
        
        color: #029dd0 !important;
    }

    .nav-menu-group {
        .nav-menu-header {
            background-color: transparent !important;
            .toggle-icon {
                color: #474547 !important;
            }
            
            font-weight: bolder !important;
        }
        
        &:has(a.nav-menu-item):has(.active) {
            > .nav-menu-header {
                color: #029dd0 !important;
            }
        }
    }

    a {
        display: block;
        color: inherit !important;
        text-decoration: none !important;

        &.active {
            font-weight: bolder !important;
            color: #029dd0 !important;
            background-color: transparent !important;
        }
    }
}