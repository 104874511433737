.wrapper-class {
    border: 1px solid #ced4da;
    max-width: 500px;
}

.toolbar-class {
    border: none;
    border-bottom: 1px solid #ced4da;

    .rdw-option-wrapper {
        border: none;
    }
}

.editor-class {
    min-height: 400px;
    padding: 10px;
}

.rdw-link-modal {
    height: auto;
}
