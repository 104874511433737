#connected-apps-tooltip-id {
    background-color: #6e6e6e;
}

.tooltip-inner {
    max-width: 270px;
    padding: 9px 5px 9px 5px;
}

.connected-apps-tooltip .tooltip.bs-tooltip-top .arrow::before {
    border-top-color: #6e6e6e;
}

.connected-apps-tooltip .tooltip.bs-tooltip-bottom .arrow::before {
    border-bottom-color: #6e6e6e;
}

.connected-apps-tooltip .tooltip.bs-tooltip-start .arrow::before {
    border-left-color: #6e6e6e;
}

.connected-apps-tooltip .tooltip.bs-tooltip-end .arrow::before {
    border-right-color: #6e6e6e;
}
