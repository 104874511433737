// React Navigation Bar section
.nav-tabs {
    .nav-item {
        button {
            padding-left: 0px;
            padding-right: 0px;
        }
    }

    .nav-item:not(:last-child) {
        button {
            margin-right: 20px;
        }
    }

    .nav-link.active {
        border-width: 0 0 3px 0;
        border-radius: 0px;
        border-bottom-color: $rd-navy;
        color: $rd-navy;
    }
}

#header-nav-bar {
    z-index: 1000;

    .navbar-toggler {
        padding-left: 20px;
        padding-right: 20px;
    }
}
a .nav-link {
    color: $black !important;
}
.nav-link {
    .user-control {
        display: inline-block;
        padding-right: 10px;
    }

    svg {
        pointer-events: none;
    }
}

.overflow-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 200px;
}

.align-center {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    padding-left: 5px;
    padding-right: 0px;
    background-color: #fff;
}

.navbar {
    border-bottom: 1px solid $light-grey-1;
    padding: 0;

    .navbar-nav {
        .nav-item {
            min-width: 65px;
            text-transform: uppercase;
            letter-spacing: 0.1rem;

            a,
            button {
                padding: 18px 20px;
                color: $black;
                border: none;
                border-radius: 0;
            }

            .log-off {
                padding-left: 8px;
            }

            a:hover,
            button:hover {
                color: $rd-web-blue;
            }

            .current-nav-location {
                color: $rd-navy;
            }
        }

        .break-line {
            display: block;
        }

        .dropdown > .dropdown-menu {
            > .dropdown-item {
                padding: 5px 20px;
                color: $dark-grey;
                width: 230px;
                a:hover,
                button:hover {
                    background-color: $dark-grey;
                }
            }

            .organisation-dropdown-item {
                padding: 18px 16px;
                border-left: 6px solid transparent;

                &.selected {
                    border-left-color: $rd-navy;
                    color: $rd-navy;
                }
            }

            .dropdown-header {
                text-transform: uppercase;
                font-weight: bold;
                padding: 5px 20px;
            }

            .organisation-dropdown-item:hover {
                background-color: $rd-pale-blue;
            }
        }
    }
}

.navbar-brand-container {
    width: 33%;
    display: flex;

    .navbar-brand {
        padding-left: 10px;
        padding-right: 1rem;
        margin-right: 0;

        .res-developer-icon {
            margin: 10px 0;
            width: auto;
        }
    }
}

.page-nav-container {
    font-size: 16px;
    width: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex: 1;
}

.user-section-container {
    width: 33%;
    display: flex;
    justify-content: flex-end;

    button:hover {
        background-color: transparent;
    }

    .nav-item {
        .user-section-info {
            display: flex;
            align-items: center;

            a {
                color: $rd-web-blue;
                padding-left: 0;
            }
        }
    }

    .user-section {
        border-left: 1px solid $mid-grey-2;
        text-align: center;

        a:first-child {
            color: $dark-grey;
            white-space: initial;
            padding: 10px 20px;
        }

        a:first-child:hover {
            background-color: $rd-pale-blue;
            color: $dark-grey;
        }

        a:last-child:hover {
            background-color: transparent;
        }

        .organisation-divider {
            margin-top: 0;
        }
    }

    .dropdown-items-row {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
    }

    .dropdown-item-icon {
        padding-right: 5px;
    }
    .dropdown-menu {
        width: 100%;
    }
}
