.settings-page {
  background-color: $white;

  .help-block {
    color: $rd-red;
  }

  .settings-table {
    table-layout: fixed;

    @at-root #{&}__button-column {
      width: 140px;
    }

    @at-root #{&}__name-column {
      width: 200px;
    }

    @at-root #{&}__secret-column {
      width: 300px;
    }

    @at-root #{&}__token-column {
      overflow-wrap: break-word;
    }

    th {
      min-width: 200px;
    }

    table,
    th,
    td {
      border-left: none;
      border-right: none;
    }
  }

  .epos-credentials-form {
    width: 40%;
  }

  .credential-input-group {
    flex-wrap: nowrap;
    height: 36px;

    input:read-only,
    button {
      background-color: $white;
      border-color: $mid-grey-1;
    }

    .credential-input {
      position: relative;
      display: inline-block;
      width: 85%;
    }

    .show-hide-button {
      position: absolute;
      right: 10px;
      top: 10px;
      background: none !important;
      border: none;
      outline: none !important;
      box-shadow: none !important;
      padding: 0 !important;
      color: #029dd0;
    }
  }

  .copy-button {
    button {
      outline: none !important;
      box-shadow: none !important;
      margin-left: -3px;
    }

    :hover,
    :focus {
      background-color: #e5f8fd;
      border-color: #ced4da;
    }
  }

  .settings-badge {
    padding: 0.5em 0.7em;
    font-weight: 500;
    font-size: 50%;
    vertical-align: middle;
    margin-left: 20px;
    color: $white;
  }
}
