.mobile-nav {
  position: fixed;
  top: 56px;
  z-index: 1000;
  width: 100%;
  background-color: #f0f0f0;

  @at-root #{&}__header {
    background-color: #f0f0f0;
    color: #029dd0;
    font-family: "Open Sans SemiBold", sans-serif;
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-transform: uppercase;

    @at-root #{&}__hide {
      transform: rotate(180deg) translateY(-6px);
      float: right;
      cursor: pointer;
    }
  }

  @at-root #{&}__sidebar-container {
    display: none;
    width: 100%;

    @at-root #{&}__hide {
      position: absolute;
      cursor: pointer;
      width: 15px;
      top: 10px;
      right: 12px;
    }

    .dp-sidebar {
      border-right-style: none;
    }
  }
}

.mobile-nav--expanded {
  height: calc(100% - 56px);
  overflow-y: auto;
  .mobile-nav__header {
    display: none;
  }

  .mobile-nav__sidebar-container {
    display: block;
    height: 100%;
  }
}
