.rd-input-container {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.rd-input-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: relative;
    display: inline-block;
    vertical-align: bottom;
    margin: 0px 5px;
    height: 16px;
    width: 16px;
    background-color: #fff;
    border: 1px solid;

    border-color: $mid-grey-2;
}

input[type='checkbox'] ~ .checkmark {
    border-radius: 3px;
}

input[type='radio'] ~ .checkmark {
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.rd-input-container:hover input ~ .checkmark {
    background-color: #fff;
}

/* When the checkbox is checked, add a blue background */
.rd-input-container input[type='checkbox']:checked ~ .checkmark {
    background-color: $rd-web-blue;
}

.rd-input-container input[type='checkbox']:checked:disabled ~ .checkmark {
    background-color: $mid-grey-1;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: '';
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.rd-input-container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.rd-input-container input[type='checkbox'] ~ .checkmark:after {
    left: 5px;
    top: 2px;
    width: 4px;
    height: 7px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.rd-input-container input[type='radio'] ~ .checkmark:after {
    top: 1.5px;
    left: 1.5px;
    width: 80%;
    height: 80%;
    border-radius: 50%;
    background: $rd-web-blue;
}
