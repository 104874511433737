.input-group {
    i:hover {
        cursor: pointer;
    }

    i svg {
        height: 15px;
    }

    input + div.input-group-append.password-toggle {
        border-left: 0px !important;
        position: absolute;
        right: 0px;
    }

    input.is-invalid ~ div.input-group-append {
        i {
            border-color: $rd-orange;
        }
    }

    .input-group-append,
    .input-group-prepend {
        i {
            border: 1px solid #ced4da;
            display: block;
            margin: auto;
            padding: 9px 5px;
            height: 36px;
        }
    }

    @at-root #{&}-append {
        &:last-child {
            i {
                border-radius: 0px 3px 3px 0px;
            }
        }
    }

    @at-root #{&}-prepend {
        &:first-child {
            i {
                border-radius: 3px 0px 0px 3px;
            }
        }
    }
}
