.logs-page {
    background-color: $white;
    min-height: calc(130vh - 46px);
    margin-right: 20px;

    .toggle-button {
        list-style-type: none;
        white-space: nowrap;
        padding: 0;
        margin: 0;

        li {
            display: inline-block;
            padding-top: 9px;
            padding-bottom: 9px;
            padding-left: 13px;
            padding-right: 13px;
        }

        @at-root #{&}__label {
            font-family: 'Open Sans SemiBold', sans-serif;
            font-size: 12px;
            color: #474547;
            text-transform: uppercase;
        }

        @at-root #{&}__yes-no {
            position: relative;
            width: 80px;
            cursor: pointer;
            border-radius: 3px;
            background-color: #b2b2b1;
            text-align: right;

            @at-root #{&}__switch {
                position: absolute;
                display: inline-block;
                background-color: white;
                border-radius: 3px;
                width: 26px;
                height: 26px;
                left: 5px;
                top: 5px;
            }

            @at-root #{&}__yes {
                display: none;
            }

            @at-root #{&}__no {
                display: inline-block;
            }
        }

        @at-root #{&}__yes-no--active {
            background-color: #054169;
            color: white;
            text-align: left;

            .toggle-button__yes-no__yes {
                display: inline-block;
            }

            .toggle-button__yes-no__no {
                display: none;
            }

            .toggle-button__yes-no__switch {
                right: 5px;
                top: 5px;
                left: auto;
            }
        }
    }

    .log-list {
        @at-root #{&}__filters {
            padding-top: 10px;
            padding-bottom: 10px;
            height: 60px;

            label {
                margin-right: 10px;
            }
        }

        @at-root #{&}__no-results {
            text-align: center;
        }

        @at-root #{&}__pagination {
            display: block;
            text-align: center;
            padding-top: 30px;
            padding-bottom: 30px;

            @at-root #{&}__button {
                font-family: 'Open Sans SemiBold', sans-serif;
                font-size: 12px;
                color: #057cac;
                padding-top: 10px;
                padding-bottom: 10px;
                padding-left: 24px;
                padding-right: 24px;
                border: 1px solid #00b7f0;
                box-sizing: border-box;
                border-radius: 3px;
            }
        }
    }

    .log-table {
        width: 100%;
        border-bottom: 0.5px solid #cbcbcb;

        @at-root #{&}__goto-detail-page {
            margin-right: 20px;
            transform: translateY(-1px);
        }

        @at-root #{&}__timestamp-column {
            width: 230px;
        }

        @at-root #{&}__request-summary {
            display: inline-block;
            max-width: 540px;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
        }
    }

    .status-dot {
        display: inline-block;
        margin-right: 20px;
        background-color: #00a099;
        width: 10px;
        height: 10px;
        border-radius: 50%;
    }

    .status-dot--fail {
        background-color: $rd-error-red;
    }
}
