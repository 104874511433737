.summaryRow {
    border: none !important;
}
.summaryLabel {
    font-weight: bold;
}

.summaryRow:hover {
    background-color: transparent;
}

.log-details {
    background-color: $white;
    max-width: 1100px;

    .code-separator {
        border-bottom: 1px solid #979797;
    }

    .back-to-logs-container {
        cursor: pointer;
        margin-top: 30px;

        .back-icon {
            svg {
                height: 16px;
                width: 16px;
            }
        }
    }

    @at-root #{&}__back-to-log-list {
        font-family: 'Open Sans SemiBold', sans-serif;
        font-size: 12px;
        color: #474547;
        text-transform: uppercase;
        cursor: pointer;
        padding-top: 10px;
        padding-bottom: 50px;
    }

    @at-root #{&}__request-title {
        font-family: 'Open Sans SemiBold', sans-serif;
        font-size: 30px;
        color: #474547;
        border-top: 1px solid #979797;
        padding-top: 45px;
        padding-bottom: 5px;
    }

    @at-root #{&}__api-call {
        padding-top: 20px;
        padding-left: 30px;
        padding-bottom: 15px;
        display: block;
        overflow-wrap: break-word;

        .status-dot {
            position: absolute;
            transform: translate(-30px, 18px);
            margin-right: 20px;
            background-color: #00a099;
            width: 10px;
            height: 10px;
            border-radius: 50%;
        }

        .status-dot--fail {
            background-color: $rd-error-red;
            position: absolute;
            transform: translate(-30px, 18px);
            margin-right: 20px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
        }

        @at-root #{&}__method-url {
            font-family: 'Open Sans Regular', sans-serif;
            font-size: 22px;
            color: #474547;
            padding: 10px 0px;
        }
    }

    @at-root #{&}__summary {
        padding-top: 20px;
        padding-bottom: 20px;
        border-top: 1px solid #cbcbcb;

        @at-root #{&}__title {
            font-family: 'Open Sans Regular', sans-serif;
            font-size: 22px;
            color: #474547;
            padding-bottom: 10px;
        }

        .row {
            div:first-of-type {
                font-weight: bold;
            }

            div {
                padding-top: 2px;
                padding-bottom: 2px;
            }
        }
    }
}

.error-log {
    padding-top: 20px;
    padding-bottom: 20px;

    .row {
        padding-top: 5px;
        padding-bottom: 5px;
        margin-left: 5px;
        margin-right: 5px;
    }

    @at-root #{&}__summary {
        border-top: 0.5px solid #cbcbcb;
    }

    @at-root #{&}__summary:hover {
        background-color: #e5f8fd;
    }

    @at-root #{&}__stack-trace {
        margin-top: 20px;
    }

    .col-sm-2,
    .col-sm-12 {
        padding-left: 0px;
        padding-right: 0px;
    }

    @at-root #{&}__title {
        font-family: 'Open Sans Bold', sans-serif;
        font-size: 13px;
        color: #474547;

        div {
            padding-top: 2px;
            padding-bottom: 2px;
        }
    }

    @at-root #{&}__value {
        font-family: 'Open Sans Regular', sans-serif;
        font-size: 13px;

        div {
            padding-top: 2px;
            padding-bottom: 2px;
        }
    }

    @at-root #{&}__expand-details {
        float: right;
        transform: translateY(10px);
    }
}
