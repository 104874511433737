.dp-header {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 56px;
  border-bottom: 1px solid #cbcbcb;
  background-color: white;
  z-index: 1000;
  padding-left: 5px;
  display: flex;
  flex-direction: row;

  @at-root #{&}__row {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  @at-root #{&}__logo {
    display: flex;
    width: 33%;
  }

  @at-root #{&}__logoImage {
    padding-left: 5px;
    margin: 15px 0px;
  }

  @at-root #{&}__links {
    width: 33%;
    display: flex;
    flex-direction: row;
    padding-top: 3px;
    align-items: center;
    justify-content: center;

    a {
      text-decoration: none;
    }

    @at-root #{&}__link {
      font-family: "Open Sans SemiBold", sans-serif;
      font-size: 16px;
      color: #054169;
      padding: 25px;
    }

    @at-root #{&}__link--active {
      color: #029dd0;
    }
  }

  @at-root #{&}__user {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 33%;
    font-family: "Open Sans Regular", sans-serif;
    font-size: 12px;
    color: #6e6e6e;
    align-items: center;

    @at-root #{&}__icon {
      padding-right: 5px;
      transform: translateY(-1px);
    }

    @at-root #{&}__log-off {
      font-family: "Open Sans SemiBold", sans-serif;
      color: #029dd0;
    }
  }
}
