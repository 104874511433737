.rd-spinner {
    text-align: center;

    img {
        height: 100px;
    }
}

.rd-spinner-small {
    text-align: center;

    img {
        height: 50px;
    }
}

.rd-spinner-wrapper {
    text-align: center;
    transform: translateX(-50%);
    top: 50%;
    left: 50%;
    position: absolute;

    img {
        height: 50px;
    }
}

.rd-spinner-wrapper-no-margin {
    @extend .rd-spinner-wrapper;
    top: 0;
    position: relative;
}
