.button-group {
    display: inline-flex;
    align-items: baseline;
    white-space: nowrap;
    padding: 0;
    margin: 0;

    ul {
        list-style-type: none;
        padding: 0px;
    }

    li {
        display: inline-block;
        padding-top: 9px;
        padding-bottom: 9px;
        padding-left: 13px;
        padding-right: 13px;
    }

    @at-root #{&}__label {
        font-family: 'Open Sans SemiBold', sans-serif;
        font-size: 12px;
        color: #474547;
        text-transform: uppercase;
        text-align: right;
    }

    @at-root #{&}__item {
        cursor: pointer;
        text-align: center;
        min-width: 100px;
        border-right: 1px solid #b2b2b1;
        border-top: 1px solid #b2b2b1;
        border-bottom: 1px solid #b2b2b1;
    }

    @at-root #{&}__item:first-of-type {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-left: 1px solid #b2b2b1;
    }

    @at-root #{&}__item:last-of-type {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
    }

    @at-root #{&}__item--active {
        background-color: #054169;
        color: white;
        border-color: #054169 !important;
    }
}
