.landing-page {
    width: 100%;
    overflow-y: auto;
    margin-bottom: 30px;

    h4,
    h5 {
        color: $rd-navy;
    }
}

.header-container {
    background-color: $rd-navy;
    height: 380px;
    margin-bottom: 30px;
}

.header-bg {
    background-image: url('../../content/images/landing-page-banner-2.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    width: 60%;
    margin: 0px auto;
    height: inherit;
    display: grid;
    align-items: center;
}

.landing-header {
    padding: 10px;
    max-width: 500px;

    h1 {
        font-size: 60px;
        letter-spacing: -0.2px;
        line-height: 60px;
    }

    .btn-primary:hover {
        border: 1px solid $white;
        background-color: $rd-navy !important;
    }
}

.header-title {
    @at-root #{&}__light-header {
        font-family: 'Open Sans', sans-serif;
        font-weight: 100;
        color: $white;
        margin-bottom: 0;
    }

    @at-root #{&}__header-2 {
        font-weight: 500;
        color: $white;
    }

    @at-root #{&}__description {
        font-family: 'Open Sans', sans-serif;
        color: $white;
        font-size: 20px;
        letter-spacing: 0.04em;
        font-weight: 100;
        line-height: 2rem;
    }
}

.landing-content {
    padding: 10px 0 0;
    width: 60%;
    margin: 0px auto;

    a {
        text-decoration: none;
        color: $rd-black;
    }

    a:hover {
        color: $rd-black;
    }

    .general-settings {
        margin-top: 40px;

        input,
        textarea,
        .input-group,
        .rd-dropdown {
            max-width: 800px;
            min-width: 390px;
        }

        .login-button {
            max-width: 200px;
            min-width: unset;
        }

        .user-first-name {
            margin-right: 20px;
        }

        .user-new-password {
            margin-right: 20px;
        }

        .user-new-email {
            margin-right: 20px;
        }

        .button-link {
            color: $rd-web-highlight;
        }
    }
}

.settings {
    padding: 10px 0 0;
    margin: 0px auto;

    a {
        text-decoration: none;
        color: $rd-black;
    }

    a:hover {
        color: $rd-black;
    }

    input,
    textarea,
    .input-group,
    .rd-dropdown {
        max-width: 800px;
        min-width: 390px;
    }

    .login-button {
        max-width: 200px;
        min-width: unset;
    }

    .user-first-name {
        margin-right: 20px;
    }

    .user-new-password {
        margin-right: 20px;
    }

    .button-link {
        color: $rd-web-highlight;
    }
}

.lg-nav-button {
    display: flex;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0 2px 8px 0 rgba(128, 145, 161, 0.43);
    padding: 30px;
    margin-bottom: 20px;
    height: 120px;

    .nav-description {
        margin-left: 30px;
    }

    svg {
        width: 60px;
        height: 60px;
    }

    &:hover {
        background-color: $rd-pale-blue;
        border: 1px solid $rd-web-highlight;
    }

    button {
        margin-left: auto;
    }
}

.lg-shortcut-button {
    border-radius: 8px;
    box-shadow: 0 2px 8px 0 rgba(128, 145, 161, 0.43);
    padding: 30px;
    margin-bottom: 20px;
    height: 180px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    svg {
        width: 40px;
        height: 40px;
        margin-bottom: 10px;
        margin-right: 50px;
    }

    &:hover {
        background-color: $rd-pale-blue;
        border: 1px solid $rd-web-highlight;
    }
}

.lg-admin-shortcut {
    svg {
        width: 40px;
        height: 40px;
    }
}
