.code {
    background-color: #f0f0f0;
    padding: 1px;
    margin-bottom: 10px;

    @at-root #{&}__header {
        border-bottom: 1px solid #979797;
        padding: 13px;
        padding-left: 17px;
        position: relative;

        @at-root #{&}__header,
            .fail {
            background-color: #fbe8e5;
            color: $rd-error-red;
        }

        @at-root #{&}__title {
            font-family: 'Open Sans Bold', sans-serif;
            font-size: 12px;
            text-transform: uppercase;
        }
    }

    pre {
        overflow-x: hidden;
        display: block;
        padding: 9.5px;
        margin: 0 0 10px;
        font-size: 13px;
        line-height: 1.42857143;
        word-break: break-all;
        word-wrap: break-word;
        color: #333333;
        background-color: #f5f5f5;
        border: 1px solid #cccccc;
        border-radius: 3px;
        margin: 15px;

        &:hover {
            overflow-x: visible;
        }
    }

    @at-root #{&}__toolbox {
        position: absolute;
        left: auto;
        right: 0;
        bottom: auto;
        top: 0;
        list-style-type: none;
        padding-left: 0;

        li {
            display: inline-block;
        }

        @at-root #{&}__language {
            font-family: 'Open Sans Bold', sans-serif;
            font-size: 12px;
            color: #474547;
            text-transform: uppercase;
            width: 100px;
            border-left: 1px solid #979797;
            padding: 13px;
        }

        @at-root #{&}__copy {
            cursor: pointer;
            border-left: 1px solid #979797;
            padding: 13px;
        }
    }
}

code {
    visibility: visible;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 2px 4px;
}
