.certification-page {
    .certification-header {
        background-color: $light-grey-1;
        padding: 15px 0px;
        font-weight: bold;
        border-bottom: 2px $mid-grey-2 solid;
    }

    .certification-header:hover {
        background-color: $mid-grey-2;
    }
    svg {
        width: 13px;
        height: 13px;
    }
}
