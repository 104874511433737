.dp-sidebar {
    a {
        text-decoration: none !important;
    }

    div.dp-sidebar__base {
        border-bottom: 0.5px solid #cbcbcb;
        padding-left: 15px;
        margin-top: 0px !important;
    }

    div:first-of-type {
        margin-top: 0;
    }

    .active {
        color: #029dd0 !important;
        border-right: 3px solid #029dd0;
    }

    @at-root #{&}__header-name {
        font-family: 'Open Sans Bold', sans-serif;
        font-size: 12px;
        color: #474547 !important;
        display: flex;
        padding-top: 17px;
        padding-bottom: 17px;

        a {
            width: 100%;
            color: #474547 !important;
        }
        img {
            float: right;
            padding: 5px 15px;
            cursor: pointer;
        }
    }

    @at-root #{&}__category-list {
        margin-bottom: 10px;
        border-bottom: 0.5px solid #cbcbcb;
    }

    @at-root #{&}__category-list:last-of-type {
        border-bottom: none;
    }

    @at-root #{&}__category-name {
        font-family: 'Open Sans Bold', sans-serif;
        font-size: 12px;
        color: #6e6e6e;
        padding: 5px 0px;
        display: flex;

        a {
            width: 100%;
            color: #474547 !important;
        }

        img {
            float: right;
            padding: 5px 15px;
            cursor: pointer;
        }
    }

    @at-root #{&}__articles {
        list-style-type: none;
        padding: 0px 0px 15px 0px;
        margin: 0;

        @at-root #{&}__item {
            @at-root #{&}__name {
                font-family: 'Open Sans Regular', sans-serif;
                font-size: 12px;
                color: #6e6e6e;
                margin-top: 5px !important;
                padding: 5px 0px;
                display: block;
            }
        }
    }

    @at-root #{&}__link--active {
        color: #029dd0;
    }

    @at-root #{&}__version {
        font-family: 'Open Sans Regular', sans-serif;
        font-size: 12px;
        color: #6e6e6e;
    }

    @at-root #{&}__functions {
        list-style-type: none;
        padding: 0;
        margin: 0;

        @at-root #{&}__item {
            font-family: 'Open Sans Bold', sans-serif;
            font-size: 12px;
            color: #029dd0;
            text-transform: uppercase;
            padding-top: 15px;
            padding-bottom: 15px;
            padding-left: 14px;
            border-bottom: 0.5px solid #cbcbcb;
            border-right: 3px solid #029dd0;
        }
    }

    @at-root #{&}__submenu {
        list-style-type: none;
        border-left: 0.5px solid #cbcbcb;
        padding-left: 10px;

        li {
            margin: 10px 0px;
        }

        a {
            font-family: 'Open Sans Regular', sans-serif;
            font-size: 12px;
            color: #6e6e6e;
        }

        @at-root #{&}__item--active {
            color: #029dd0 !important;
        }
    }
}

.sidebar-column {
    position: fixed !important;
    overflow-y: auto;
    top: 56px;
    height: calc(100vh - 56px);
    background-color: #f0f0f0;
    border-right: 0.5px solid #cbcbcb;
}

.dp-sidebar--home-page {
    padding-left: 0px;
    padding-top: 0px;
    > div {
        border-bottom: 0.5px solid #cbcbcb;
        padding-left: 15px;
        padding-top: 15px;
        padding-bottom: 15px;
        margin-top: 0px !important;

        a {
            color: #474547;
        }
    }
}
